import React, { useEffect, useState } from 'react';
import TicketSearchBar from './TicketSearchBar';
import Head from '../SearchUserKey/Table/Head';
import Body from '../SearchUserKey/Table/Body';

import { getSMTItemsFormatted } from '../../../utils/smtFormatting';
import { fetchSmtGetTicketDetailsById } from '../../../api/ticketsEndpoints';
import SAPDisclaymer from './Table/SAPDisclaymer';
import TicketSearchLoader from './TicketSearchLoader';
import { useTranslation } from 'react-i18next';

const UserKeySection = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const { t } = useTranslation();

  const columns = [
    { label: 'Ticket ID', accessor: 'ticketId', sortable: true },
    { label: t('ui_spear.myTickets.Name'), accessor: 'name', sortable: true },
    {
      label: t('ui_spear.myTickets.Status'),
      accessor: 'status',
      sortable: true
    },
    {
      label: t('ui_spear.myTickets.Last_update'),
      accessor: 'lastUpdate',
      sortable: true
    },
    { label: t('ui_spear.myTickets.Type'), accessor: 'type', sortable: true },
    {
      label: t('ui_spear.myTickets.Priority_for_company'),
      accessor: 'priority',
      sortable: true
    }
  ];

  const localizedLabels = {
    title: t('ui_spear.sapKeyUser.Search_by_TicketID'),
    searchLabel: `${t('ui_spear.sapKeyUser.Search_for_any')}...`,
    disclaymer: t('ui_spear.sapKeyUser.As_a_SAP_KEY_USER'),
    errorMsg: t('ui_spear.sapKeyUser.Ticket_does_not_exist')
  };

  useEffect(() => {
    const searchTicketById = async () => {
      const items = await fetchSmtGetTicketDetailsById(searchText, 1);
      if (items) {
        const tempItems = getSMTItemsFormatted(items, true);
        setCurrentItems(tempItems);
      } else {
        //case for not searched ticket
        setSearchText('');
      }
    };

    if (searchText && searchText !== '' && searchText !== undefined) {
      setLoader(true);
      searchTicketById();
    } else {
      setSearchError(true);
      setTimeout(() => {
        setSearchError(false);
        setCurrentItems([]);
      }, 2000);
    }
  }, [searchText]);

  useEffect(() => {
    if (currentItems.length >= 0) setLoader(false);
    setSearchError(false);
  }, [currentItems]);

  return (
    <div className="mx-10 mb-8">
      <TicketSearchBar
        searchError={searchError}
        setSearchText={setSearchText}
        searchText={searchText}
        searchLabel={localizedLabels.searchLabel}
        errorMsg={localizedLabels.errorMsg}
      />
      <h4 className="mt-5 text-[24.5px] font-Bosch_sans_light font-normal">
        {localizedLabels.title}
      </h4>
      <div className="">
        <table className="w-full table-auto bg-white my-5">
          {currentItems.length > 0 || searchText.length > 0 ? (
            <>
              {loader ? (
                <TicketSearchLoader />
              ) : (
                <>
                  <Head columns={columns} />
                  <Body items={currentItems} />
                </>
              )}
            </>
          ) : (
            <SAPDisclaymer disclaymer={localizedLabels.disclaymer} />
          )}
        </table>
      </div>
    </div>
  );
};

export default UserKeySection;
