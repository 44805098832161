import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const FeedbackThanksModal = ({ openThanksModal, setOpenThanksModal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenThanksModal(false);
  };

  return (
    <>
      <Dialog
        open={openThanksModal}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="px-[50px] py-2 flex justify-center content-center">
          <div className="fixed rounded-full bg-lime-500 h-[65px] w-[65px] flex justify-center content-center mt-[-35px]">
            <span className="boschicon-bosch-ic-checkmark text-white text-[55px] m-auto"></span>
          </div>
          <Typography className="text-[24.5px] font-Bosch_sans_light text-center mt-12">
            {' '}
            {t('ui_spear.search.Modal.Feedback_sent')}{' '}
          </Typography>
        </DialogTitle>
        <DialogContent className="text-[16px] font-Bosch_sans-light text-center py-4 px-5">
          <Typography className="p-2">
            {t('ui_spear.search.Modal.Thank_you_very_much')}
          </Typography>
          <Typography className="p-2">
            {t('ui_spear.search.Modal.We_really_appreciate_you')}
          </Typography>
          <Button
            className="h-[42px] rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[14px] mr-1.5 p-3 my-5 float-center"
            onClick={handleClose}
          >
            <Typography className="text-white normal-case p-6">
              {t('ui_spear.search.ModalButton.Close')}
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeedbackThanksModal;
