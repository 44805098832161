import {
  Button,
  Checkbox,
  Container,
  Typography,
  FormControlLabel,
  styled
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BpIcon = styled('span')(({ theme }) => ({
  width: 24,
  height: 24,
  backgroundColor: '#dfdfe0',
  'input:hover ~ &': {
    backgroundColor: '#7caecf'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#005691',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  }
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const FeedbackCommentLowScore = (props) => {
  const { t } = useTranslation();
  return (
    <Container className="text-[14px]">
      <Typography className="font-Bosch_sans my-2.5">
        {t('ui_spear.support.search_sorry_hear_that_reason_negative')}
      </Typography>
      <div>
        {props.checkboxes.map((checkbox, index) => {
          return (
            <div className="font-Bosch_sans_light flex" key={checkbox.reason}>
              <FormControlLabel
                control={
                  <BpCheckbox
                    for={`c${index}`}
                    onClick={() => props.onCheckboxClick(index)}
                  />
                }
                label={checkbox.reason}
              />
            </div>
          );
        })}
      </div>

      <div>
        <textarea
          type="text"
          className="p-[18px] border-[#bfc0c2] border-solid border w-full resize-none focus:bg-[#efeff0] focus:border-[#efeff0] focus:outline-none h-[80px] my-4"
          placeholder={t('ui_spear.search.Modal.Tell_us_more')}
          value={props.comment}
          onChange={props.onChange}
        ></textarea>

        <Button
          className="h-[42px] rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[14px] mr-1.5 p-3 mb-4 float-right"
          onClick={props.sendFeedbackLowScore}
        >
          <Typography className="text-white normal-case p-2.5">
            {t('ui_spear.search.ModalButton.Send_Feedback')}
          </Typography>
        </Button>
      </div>
    </Container>
  );
};

export default FeedbackCommentLowScore;
