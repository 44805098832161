import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  Typography,
  styled
} from '@mui/material';
import { ReactComponent as EmptyStar } from '../../static/empty_star.svg';
import { ReactComponent as FilledStar } from '../../static/filled_star.svg';
import FeedbackComments from './FeedbackComments/FeedbackComments';
import FeedbackCommentLowScore from './FeedbackComments/FeedbackCommentLowScore';
import FeedbackThanksModal from './FeedbackComments/FeedbackThanksModal';
import { useTranslation } from 'react-i18next';

const StyledRating = styled(Rating)({
  '& .MuiRating-icon': {
    margin: '0 4px',
    color: '#005691'
  },
  '& .MuiRating-iconFilled': {
    color: '#005691'
  },
  '& .MuiRating-iconHover': {
    color: '#005691'
  }
});

const GeneralFeedbackModal = (props) => {
  const { t } = useTranslation();
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [open, setOpen] = useState(false);
  const [openThanksModal, setOpenThanksModal] = useState(false);
  const [fiveStarsState, setFiveStarsState] = useState({
    show: true,
    starRating: null
  });
  const [feedbackCommentState, setFeedbackCommentState] = useState({
    show: false,
    comment: ''
  });
  const [feedbackCheckboxesState, setFeedbackCheckboxesState] = useState({
    comment: '',
    checkboxes: [
      {
        checked: false,
        reason: t('ui_spear.support.search_was_not_able_to_find')
      },
      { checked: false, reason: t('ui_spear.support.search_was_not_easy_use') },
      {
        checked: false,
        reason: t('ui_spear.support.search_no_relevant_results')
      },
      {
        checked: false,
        reason: t('ui_spear.support.search_content_quality_non_sastis')
      },
      { checked: false, reason: t('ui_spear.support.search_other') }
    ]
  });

  useEffect(() => {
    //when the searchedquery change reset feedbackSent value
    if (feedbackSent) setFeedbackSent(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchedQuery]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    cleanModalFields();
    setOpen(false);
  };

  const cleanModalFields = () => {
    setFiveStarsState({ show: true, starRating: null });
    setFeedbackCommentState({ show: false, comment: '' });
    setFeedbackCheckboxesState({
      comment: '',
      checkboxes: [
        {
          checked: false,
          reason: t('ui_spear.support.search_was_not_able_to_find')
        },
        {
          checked: false,
          reason: t('ui_spear.support.search_was_not_easy_use')
        },
        {
          checked: false,
          reason: t('ui_spear.support.search_no_relevant_results')
        },
        {
          checked: false,
          reason: t('ui_spear.support.search_content_quality_non_sastis')
        },
        { checked: false, reason: t('ui_spear.support.search_other') }
      ]
    });
  };

  const onRatingSelectionHandler = (event, starRating) => {
    if (starRating <= 2 && starRating !== null) {
      setFiveStarsState({ ...fiveStarsState, starRating: starRating });
      setFeedbackCommentState({ ...feedbackCommentState, show: true });
    } else {
      setFiveStarsState({ ...fiveStarsState, starRating: starRating });
      setFeedbackCommentState({ ...feedbackCommentState, show: false });
    }
  };

  const onChangeFeedbackLowScoreHandler = (e) => {
    const commentValue = e.target.value;
    setFeedbackCheckboxesState({
      ...feedbackCheckboxesState,
      comment: commentValue
    });
  };

  const onCheckboxClick = (reasonNumber) => {
    const newCheckboxesArray = feedbackCheckboxesState.checkboxes.map(
      (checkbox, index) => {
        if (index === reasonNumber) {
          checkbox.checked = !checkbox.checked;
        }
        return checkbox;
      }
    );
    setFeedbackCheckboxesState({
      ...feedbackCheckboxesState,
      checkboxes: newCheckboxesArray
    });
  };

  const sendResultsRating = (starRating) => {
    //const role = props.userEventRole;
    const alias = props.userAlias;
    const eventDataStars = {
      searchString: props.searchedQuery.query_description
        ? props.searchedQuery.query_description
        : 'How to',
      question: 'How satisfied are you with the search?',
      type: '5 stars',
      answer: starRating
    };
    //TODO
    // socketIOConnService.sendEventData(props.analyticId, "active-questioning", eventDataStars, timestamp, hashedNtid);
  };

  const sendFeedbackComment = () => {
    if (feedbackCommentState.comment) {
      //SEND RATE FEEDBACK
      sendResultsRating();
      //const role = props.userEventRole;
      const alias = props.userAlias;
      const eventDataComments = {
        searchString: props.searchedQuery.query_description
          ? props.searchedQuery.query_description
          : 'How to',
        question: 'If you like, give more feedback here.',
        type: 'text',
        answer: feedbackCommentState.comment
      };

      setFeedbackCommentState({
        ...feedbackCommentState,
        show: false,
        comment: ''
      });
      handleClose();
      setOpenThanksModal(true);
      setFeedbackSent(true);
    }
  };

  const sendFeedbackLowScore = () => {
    /**
     * Check if a checkbox is clicked
     */
    let checkboxIsClicked = false;

    feedbackCheckboxesState.checkboxes.map(
      (checkbox) => (checkboxIsClicked = checkboxIsClicked || checkbox.checked)
    );

    if (feedbackCheckboxesState.comment || checkboxIsClicked) {
      let reasons = [];
      if (checkboxIsClicked) {
        feedbackCheckboxesState.checkboxes.map((checkbox) =>
          checkbox.checked ? reasons.push(checkbox.reason) : null
        );
      }
      sendResultsRating();
      //TODO put real searched query
      const eventDataReasons = {
        searchString: props.searchedQuery.query_description
          ? props.searchedQuery.query_description
          : 'How to',
        question:
          "sorry to hear that! What's the reason for your negative experience?",
        type: 'text list',
        answer: reasons
      };
      //TO DO get analiticId and hashedNtid from user
      /** TO DO
       * Send the data via websockets
       */
      // socketIOConnService.sendEventData(props.analyticId, "active-questioning", eventDataReasons, timestamp, hashedNtid);

      var eventDataDescription = {
        //TO DO chante to the query_descriotion value
        searchString: props.searchedQuery.query_description
          ? props.searchedQuery.query_description
          : 'How to',
        question: 'Tell us more...',
        type: 'text',
        answer: feedbackCheckboxesState.comment
      };

      /* 
            TODO Send data via web sockets 
            */
      //socketIOConnService.sendEventData(props.analyticId, 'active-questioning', eventDataDescription, timestamp, hashedNtid);

      setFeedbackCommentState({
        ...feedbackCommentState,
        show: false,
        comment: ''
      });
      setFeedbackCheckboxesState({ ...feedbackCheckboxesState, comment: '' });
      handleClose();
      setOpenThanksModal(true);
      setFeedbackSent(true);
    }
  };

  return (
    <>
      {!feedbackSent ? (
        <Button
          className="h-11 rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[12px] mr-1.5 "
          onClick={handleClickOpen}
        >
          <Typography className="text-white normal-case text-[12px] flex content-center">
            <span className="boschicon-bosch-ic-message text-[16px] mx-1"></span>
            {t('ui_spear.search.Button.Share_feedback')}
          </Typography>
        </Button>
      ) : (
        <Button className="h-11 rounded-none bg-transparent text-[12px] mr-1.5 ">
          <Typography className="text-[#535353] normal-case text-[12px] flex content-center">
            <span className="boschicon-bosch-ic-checkmark text-[16px] mx-1"></span>
            {t('ui_spear.search.Modal.Feedback_sent')}
          </Typography>
        </Button>
      )}

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="flex items-center px-[50px] pt-10">
          <div className="text-[24.5px] font-Bosch_sans_light">
            {t('ui_spear.search.Modal.Share_feedback')}
          </div>
          <IconButton
            className="boschicon-bosch-ic-close text-2xl absolute top-2 right-2 color-black"
            aria-label="close"
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>
        <DialogContent>
          {fiveStarsState.show ? (
            <Container>
              <Typography className="font-Bosch_sans text-[14px] my-2.5">
                {t('ui_spear.search.Modal.How_was_your_experience')}
              </Typography>
              <StyledRating
                emptyIcon={<EmptyStar />}
                icon={<FilledStar />}
                value={fiveStarsState.starRating}
                onChange={(event, newValue) => {
                  onRatingSelectionHandler(event, newValue);
                }}
                className="m-[-18px] p-0 scale-[.8] my-2.5"
                defaultValue={null}
              />
            </Container>
          ) : null}
          {feedbackCommentState.show ? (
            <FeedbackCommentLowScore
              comment={feedbackCheckboxesState.comment}
              onChange={(e) => {
                onChangeFeedbackLowScoreHandler(e);
              }}
              sendFeedbackLowScore={sendFeedbackLowScore}
              onCheckboxClick={onCheckboxClick}
              checkboxes={feedbackCheckboxesState.checkboxes}
            />
          ) : (
            <FeedbackComments
              feedbackCommentState={feedbackCommentState}
              setFeedbackCommentState={setFeedbackCommentState}
              sendFeedbackComment={sendFeedbackComment}
            />
          )}
        </DialogContent>
      </Dialog>
      {openThanksModal && (
        <FeedbackThanksModal
          openThanksModal={openThanksModal}
          setOpenThanksModal={setOpenThanksModal}
        />
      )}
    </>
  );
};

export default GeneralFeedbackModal;
