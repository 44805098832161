import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FeedbackComments = ({
  feedbackCommentState,
  setFeedbackCommentState,
  sendFeedbackComment
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography className="font-Bosch_sans text-[14px] my-2.5">
        {' '}
        {t('ui_spear.search.Modal.Share_more_details')}{' '}
      </Typography>
      <textarea
        type="text"
        className="p-[18px] border-[#bfc0c2] border-solid border w-full resize-none focus:bg-[#efeff0] focus:border-[#efeff0] focus:outline-none h-[80px] my-4"
        placeholder={t('ui_spear.search.Modal.Tell_us_more')}
        value={feedbackCommentState.comment}
        onChange={(e) =>
          setFeedbackCommentState({
            ...feedbackCommentState,
            comment: e.target.value
          })
        }
      ></textarea>
      <Button
        className="h-[42px] rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[14px] mr-1.5 p-3 mb-4 float-right"
        onClick={sendFeedbackComment}
      >
        <Typography className="text-white normal-case p-2.5">
          {t('ui_spear.search.ModalButton.Send_Feedback')}
        </Typography>
      </Button>
    </Container>
  );
};

export default FeedbackComments;
