import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusCell from '../StatusCell';
import { useNavigate } from 'react-router-dom';

const OpenRow = ({
  ticketId,
  name,
  status,
  lastUpdate,
  type,
  priority,
  source,
  bgRow
}) => {
  //Translations
  const { t } = useTranslation();
  const failureLabel = t('ui_spear.createTicket.failure');
  const requestLabel = t('ui_spear.createTicket.request');
  const criticalLabel = t('ui_spear.myTickets.Priority_Critical');
  const highLabel = t('ui_spear.myTickets.Priority_High');
  const mediumLabel = t('ui_spear.myTickets.Priority_Medium');
  const lowLabel = t('ui_spear.myTickets.Priority_Low');

  const getTypeTranslation = (type) => {
    return type === 'failure' ? failureLabel : requestLabel;
  };

  const getPriorityTranslation = (priority) => {
    switch (priority) {
      case 'critical':
        return criticalLabel;
      case 'high':
        return highLabel;
      case 'medium':
        return mediumLabel;
      case 'low':
        return lowLabel;
      default:
        return lowLabel;
    }
  };

  let navigate = useNavigate();

  const goTicketDetails = () => {
    console.log('ticket details');
    navigate(`/tickets/ticket-journey/${ticketId}`);
  };

  return (
    <tr
      className={`cursor-pointer border-b hover:bg-gray-100 ${bgRow}`}
      onClick={goTicketDetails}
    >
      <td className="w-[14%] px-6 py-3 text-left">{ticketId}</td>
      <td className="w-[32%] px-6 py-3 text-left">{name}</td>
      <td className="w-[13%] px-6 py-3 text-left">
        <StatusCell status={status} />
      </td>
      <td className="w-[13%] px-6 py-3 text-left">{lastUpdate}</td>
      <td className="w-[13%] px-6 py-3 text-left capitalize">
        {getTypeTranslation(type)}
      </td>
      <td className="w-[13%] px-6 py-3 text-left capitalize">
        {getPriorityTranslation(priority)}
      </td>
    </tr>
  );
};

export default OpenRow;
