import React from 'react';
import OpenRow from './Row';

const Body = ({ data }) => {
  return (
    <tbody>
      {data.map((order, i) => {
        let bgRow = i % 2 ? 'bg-gray-50' : 'bg-white';
        return (
          <OpenRow
            key={order.ticketId}
            ticketId={order.ticketId}
            name={order.name}
            status={order.status}
            lastUpdate={order.lastUpdate}
            type={order.type}
            priority={order.priority}
            source={order.source}
            bgRow={bgRow}
          />
        );
      })}
    </tbody>
  );
};

export default Body;
