import { useEffect, useState } from 'react';
import Headline from '../Headline';
import Pagination from '../Pagination';
import Head from './Head';
import Body from './Body';
import { useTranslation } from 'react-i18next';

const OthersOpenTickets = ({
  handleSorting,
  data,
  setData,
  currentPageOpenTable,
  setCurrentPageOpenTable
}) => {
  //Translations
  const { t } = useTranslation();
  const openLabel = t('ui_spear.myTickets.Open');
  const ticketIdLabel = t('ui_spear.myTickets.Ticket_ID');
  const nameLabel = t('ui_spear.myTickets.Name');
  const statusLabel = t('ui_spear.myTickets.Status');
  const LastUpdateLabel = t('ui_spear.myTickets.Last_update');
  const personLabel = t('ui_spear.myTickets.Person');

  const columns = [
    {
      label: ticketIdLabel,
      accessor: 'ticketId',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: nameLabel,
      accessor: 'name',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: statusLabel,
      accessor: 'status',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: LastUpdateLabel,
      accessor: 'lastUpdate',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: personLabel,
      accessor: 'person',
      sortable: true,
      alignedText: 'text-left'
    }
  ];

  const [itemsPerPage, setItemsPerPage] = useState(8); //pagination
  const indexOfLastItem = currentPageOpenTable * itemsPerPage; //pagination
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; //pagination
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem); //pagination

  return (
    <div className="  mt-5 mb-8">
      <Headline title={openLabel} itemsQuantity={data.length} />
      <div className="">
        <table className="cursor-pointer w-full table-auto bg-white my-5">
          <Head
            columns={columns}
            handleSorting={handleSorting}
            data={data}
            setData={setData}
          />
          <Body data={currentItems} />
        </table>
        <Pagination
          data={data}
          itemsPerPage={itemsPerPage}
          currentPage={currentPageOpenTable}
          setCurrentPage={setCurrentPageOpenTable}
        />
      </div>
    </div>
  );
};

export default OthersOpenTickets;
