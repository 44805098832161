import TopNavbar from './TopNavbar';
import Sidebar from './Sidebar';
import ITSPLogo from './ITSPLogo';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import boschBg from '../../media/navTopBar.png';
import axiosInstance from '../../utils/axiosInstance';

const SNOW_SP_URL = process.env.REACT_APP_SNOW_SP_URL;

const Header = ({ userInfo, handleLanguageChange }) => {
  const { t } = useTranslation();
  const mainLinks = [
    { title: t('ui_spear.header.Home'), path: `${SNOW_SP_URL}` },
    { title: t('ui_spear.header.Support'), path: '/support' },
    {
      title: t('ui_spear.header.My_Favorites'),
      path: `${SNOW_SP_URL}?id=itsp_favorites`
    },
    { title: t('ui_spear.header.My_Tickets'), path: '/tickets' },
    {
      title: t('ui_spear.header.My_Approvals'),
      path: `${SNOW_SP_URL}?id=approvals`
    },
    { title: t('ui_spear.header.My_Devices'), path: `/devices` }
  ];

  const loc = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [portalLanguages, setPortalLanguages] = useState([]);

  //load language options from ServiceNow
  useEffect(() => {
    let portalLanguagesURL = `/api/v1/snow/portal/languages`;

    if (portalLanguages.length < 1) {
      axiosInstance
        .get(portalLanguagesURL)
        .then((response) => {
          if (response.status === 200 && response.data.isSuccessful) {
            setPortalLanguages(response.data.data);
          }
        })
        .catch((error) => {
          console.error(
            'Error while loading the portal languages for the profile language selector:',
            error
          );
        });
    }
  }, []);

  // Detect click outside the menu and close it
  useEffect(() => {
    function handleReziseEvent(event) {
      if (window.innerWidth > 991) {
        setIsMenuOpen(false);
      }
    }
    window.addEventListener('resize', handleReziseEvent);
    return () => {
      window.removeEventListener('resize', handleReziseEvent);
    };
  }, []);

  useEffect(() => {
    function handleReziseEvent(event) {
      if (window.innerWidth > 991) {
        setIsMenuOpen(false);
      }
    }
    window.addEventListener('resize', handleReziseEvent);
    return () => {
      window.removeEventListener('resize', handleReziseEvent);
    };
  }, []);

  return (
    <header
      className="w-full bg-white sticky top-0 z-1500"
      style={{
        borderBottom: '.1rem solid #e3e3e3',
        borderImage: `url(${boschBg}) 10% 10% 10% 10%`,
        borderStyle: 'solid none none',
        borderWidth: '.8rem 0rem 0rem'
      }}
    >
      <div
        className={`w-full h-[4.3rem] px-4 flex items-center`}
        style={{ borderBottom: '0.1rem solid #e3e3e3' }}
      >
        <section className="flex justify-between w-full items-center">
          <ITSPLogo />
          <TopNavbar
            styles={`hidden ${isMenuOpen ? '' : 'snlg:block'}`}
            location={loc.pathname}
            mainLinks={mainLinks}
            userInfo={userInfo}
            portalLanguages={portalLanguages}
            handleLanguageChange={handleLanguageChange}
          />
        </section>
        <button
          className={`boschicon-bosch-ic-list-view-mobile text text-[36px] snlg:hidden col-span-1 hover:scale-110 transition-all duration-300 ease-in-out border-2 rounded-sm w-14`}
          onClick={function () {
            setIsMenuOpen((prev) => !prev);
          }}
        ></button>
      </div>
      {
        <Collapse in={isMenuOpen}>
          <Sidebar
            styles={`snlg:hidden ${isMenuOpen ? 'block' : ''}`}
            mainLinks={mainLinks}
            userInfo={userInfo}
            portalLanguages={portalLanguages}
            handleLanguageChange={handleLanguageChange}
          ></Sidebar>
        </Collapse>
      }
    </header>
  );
};

export default Header;
