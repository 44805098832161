import React from 'react';
import ClosedRow from './Row';

const Body = ({ data }) => {
  const add3Weeks = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 22);

    if (!isNaN(newDate.getTime())) {
      const day = newDate.getDate().toString();
      const month = (newDate.getMonth() + 1).toString();

      return (
        newDate.getFullYear() +
        '/' +
        (month[1] ? month : '0' + month[0]) +
        '/' +
        (day[1] ? day : '0' + day[0])
      );
    }
  };

  const add18Months = (date) => {
    const d = new Date(date);
    const newDate = new Date(d.setMonth(d.getMonth() + 18));

    if (!isNaN(newDate.getTime())) {
      const day = newDate.getDate().toString();
      const month = (newDate.getMonth() + 1).toString();

      return (
        newDate.getFullYear() +
        '/' +
        (month[1] ? month : '0' + month[0]) +
        '/' +
        (day[1] ? day : '0' + day[0])
      );
    }
  };

  return (
    <tbody>
      {data.map((order, i) => {
        let bgRow = i % 2 ? 'bg-gray-50' : 'bg-white';
        return (
          <ClosedRow
            key={order.ticketId}
            ticketId={order.ticketId}
            name={order.name}
            solution={order.status}
            availableUntil={
              order.source === 'smt'
                ? add3Weeks(order.lastUpdate)
                : add18Months(order.lastUpdate)
            }
            person={order.person}
            closed={order.lastUpdate}
            source={order.source}
            bgRow={bgRow}
          />
        );
      })}
    </tbody>
  );
};

export default Body;
